<template>
  <div>
    <Form
      title="Añadir categoría"
      ref="academyCategoryForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.academyCategoryForm.form
      this.loading = true
      form.id_category = parseInt(form.category.split("$$")[0])
      form.id_subcategory = parseInt(form.category.split("$$")[1])

      this.$store
        .dispatch("global/create", {
          payload: {
            id_academy: this.$route.params.id,
            id_category: form.id_category,
            id_subcategory: form.id_subcategory,
            id_coach: form.id_coach,
            url_picture: form.url_picture,
          },
          route: "/academy_category/save",
          module: "academyCategory"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/academias/"+this.$route.params.id)
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
