<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('categories')
                  ? errors.first('categories')
                  : ''
              "
              :variant="
                showValidation && errors.first('categories') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Categoría - Subcategoría'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.category"
                name="categories"
                id="categories"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="categories"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('categories') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('coaches')
                  ? errors.first('coaches')
                  : ''
              "
              :variant="
                showValidation && errors.first('coaches') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Entrenador'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_coach"
                name="coaches"
                id="coaches"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="coaches"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('coaches') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1">
            <div class="flex flex-col items-center justify-around mt-2 mb-10">
              <t-input-group
                :feedback="
                  showValidation && errors.first('url_picture')
                    ? errors.first('url_picture')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_picture') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
              >
                <label class="block text-left font-bold">Foto del equipo</label>

                <cropper-upload
                  :file.sync="form.url_picture"
                  :externalUrl="
                    typeof form.url_picture === 'string'
                      ? form.url_picture
                      : null
                  "
                  title="Foto del equipo"
                  :aspectRatio="16 / 9"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </t-input-group>
            </div>
          </div>
        </div>

        <div class="w-full md:w-2/3"></div>
        <div class="w-full md:w-1/3 justify-center" v-if="deletable">
          <t-button type="button" variant="error" @click="deleteElement"
            >Eliminar categoría</t-button
          >
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")

export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        id_coach: null,
        category: null,
        url_picture: null
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    categories() {
      const data = []
      const categories = this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
      const subcategories = this.$store.getters["global/getItems"](
        "subcategory",
        "unpaginated_elements"
      )

      for (let category of categories) {
        for (let subcategory of subcategories) {
          data.push({
            index: category.id + "$$" + subcategory.id,
            id_subcategory: subcategory.id,
            id_category: category.id,
            name: category.name + " - " + subcategory.name
          })
        }
      }
      return data
    },
    coaches() {
      return this.$store.getters["global/getItems"](
        "coach",
        "unpaginated_elements"
      ).map((el) => {
        return {
          index: el.id,
          name: el.first_name + " " + el.last_name
        }
      })
    },
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    debouncedUpdate() {},
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!this.form.url_picture) {
            return this.$snotify.error(
              "La foto del equipo es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          this.$emit("save")
        }
      })
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente a la categoría.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$route.params.id2,
                  status: 2
                },
                route: "/academy_category",
                module: "academyCategory"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200)
                  this.$router.push("/academias/" + this.$route.params.id)
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    },
    getCoaches() {
      this.loadingData = true
      let form = {
        route: `coach/all`,
        module: "coach",
        isMaster: false,
        noPaginate: true,
        params: {
          active: 1,
          order_key: "first_name",
          order_value: "asc",
          verified: 1,
          id_academy: this.$route.params.id
        }
      }

      this.$store
        .dispatch("global/getItems", form)
        .then(() => {
          this.loadingData = false
        })
        .catch(() => {
          this.loadingData = false
        })
    }
  },
  created() {
    this.getItems("/subcategory/all", "subcategory", true)
    this.getItems("/category/all", "category", true)
    this.getCoaches()
  }
}
</script>
