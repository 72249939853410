var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('categories')
                ? _vm.errors.first('categories')
                : '',"variant":_vm.showValidation && _vm.errors.first('categories') ? 'danger' : '',"label":'Categoría - Subcategoría'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"categories","id":"categories","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.categories,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('categories') ? 'danger' : ''},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('coaches')
                ? _vm.errors.first('coaches')
                : '',"variant":_vm.showValidation && _vm.errors.first('coaches') ? 'danger' : '',"label":'Entrenador'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"coaches","id":"coaches","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.coaches,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('coaches') ? 'danger' : ''},model:{value:(_vm.form.id_coach),callback:function ($$v) {_vm.$set(_vm.form, "id_coach", $$v)},expression:"form.id_coach"}})],1)],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1"},[_c('div',{staticClass:"flex flex-col items-center justify-around mt-2 mb-10"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_picture')
                  ? _vm.errors.first('url_picture')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_picture') ? 'danger' : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Foto del equipo")]),_c('cropper-upload',{attrs:{"file":_vm.form.url_picture,"externalUrl":typeof _vm.form.url_picture === 'string'
                    ? _vm.form.url_picture
                    : null,"title":"Foto del equipo","aspectRatio":16 / 9,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_picture", $event)}}})],1)],1)])]),_c('div',{staticClass:"w-full md:w-2/3"}),(_vm.deletable)?_c('div',{staticClass:"w-full md:w-1/3 justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar categoría")])],1):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }